import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="RGPD e ISO 27001 - ExpertSender" description="RGPD e ISO 27001: ¡haga crecer su negocio mediante la automatización de marketing multicanal basado en datos y supere a su competencia!" />
        <Hero title="Cumplimos plenamente el RGPD y la ISO 27001" button="false" anim="false" gdpr="yes" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                  <h3>El RGPD y ExpertSender</h3>
                  <p>El Reglamento General de Protección de Datos (RGPD) es una ley europea en materia de privacidad vigente desde el 25 de mayo de 2018. El RGPD regula la forma en que las personas físicas y jurídicas pueden recopilar, utilizar y almacenar los datos personales, lo cual, a su vez, afecta al modo en que nuestros clientes utilizan la plataforma de ExpertSender.</p>
                  <h4>¿A quién es aplicable el RGPD?</h4>
                  <p id="dpo">El RGPD es aplicable a TODAS las organizaciones que recogen y tratan datos personales de individuos residentes en la UE, independientemente de la ubicación física de la empresa. Esto significa que, si tiene clientes de la UE, pero su empresa no está ubicada en la UE, tendrá que cumplir igualmente el nuevo reglamento. Puede obtener información más detallada sobre <a href="https://expertsender.com/blog/does-gdpr-apply-to-you/" target="_blank" rel="noopener noreferrer">la aplicación del RGPD en su caso aquí</a>.</p>
                  <h4>Delegado de protección de datos (DPD)</h4>
                  <p class="mar-b-3">Si desea información sobre el tratamiento de sus datos personales y el ejercicio de sus derechos, póngase en contacto con nuestro Delegado de protección de datos (Michal Kidon) a través de esta dirección de correo electrónico: dpo@expertsender.com.</p>
                  <h3>La ISO 27001 y ExpertSender</h3>
                  <p>ExpertSender optó por obtener la certificación ISO 27001 con el fin de asegurarse de preparar a nuestra organización para gestionar los riesgos inherentes al mantenimiento seguro de los recursos de información (incluidos los datos de los clientes) manejados por nuestra empresa. Con ello nuestros clientes pueden estar seguros de que, como organización, aplicamos las normas internacionales en la gestión activa de los datos comerciales y la seguridad de la información.</p>
                  <h4>¿Qué es la ISO 27001?</h4>
                  <p>La ISO 27001/IEC 27001 es un conjunto de normas que ayudan a las organizaciones a mantener la seguridad de sus recursos de información (que incluyen tanto los sistemas de información como los procesos empresariales internos). Estas normas internacionales describen los requisitos que deben cumplir los sistemas de gestión de la información de las organizaciones para garantizar su seguridad sometiéndola al control administrativo, y estipulan un conjunto de exigencias específicas para ello.</p>
                  <p>La certificación ISO/IEC 27001 demuestra que una organización ha definido e implantado las mejores prácticas en materia de seguridad de la información.</p>
                  <p><strong>Las organizaciones deben cumplir los siguientes requisitos:</strong></p>
                  <ul>
                    <li>La investigación sistemática de los riesgos para la seguridad de la información en la organización, teniendo en cuenta las amenazas, las vulnerabilidades y sus posibles repercusiones.</li><li>El diseño y aplicación de un conjunto de controles de la seguridad de la información coherente y exhaustivo u otras formas de gestión de los riesgos (p. ej., mediante la prevención o la transferencia de riesgos) para abordar los riesgos que se consideren inaceptables.</li>
                    <li>La adopción de un proceso de gestión global que garantice que los controles de seguridad de la información sigan satisfaciendo las necesidades de la organización con carácter permanente.</li>
                  </ul>
                  <h4>¿Qué significa la ISO 27001 para nuestros clientes?</h4>
                  <strong>Al utilizar los servicios de ExpertSender estará trabajando con una organización que:</strong>
                  <ul>
                    <li>Se protege de los ataques cibernéticos y toma todas las medidas necesarias para protegerle a usted.</li>
                    <li>Ha puesto en marcha controles de seguridad suficientes y proporcionales para ayudarle a proteger su información y para enviar campañas de marketing que sean conformes con los requisitos legales del RGPD.</li>
                    <li>Realiza periódicamente revisiones y auditorías internas de los sistemas de gestión de la seguridad de la información para garantizar su mejora continua.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
          
          <div className="columns is-centered">
            <div className="post__contentWrap column">
              <hr className="mar-b-3" />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-en-US-20210803-20210805081118.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 EN
              </a>
              <br />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-pl-PL-20210803-20210805081130.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 PL
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
